<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <BCard id="couponrole" :title="$t('Basic information')">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Collection name')"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Collection name"
                  >
                    <b-form-input
                      id="combo-name"
                      v-model="model.name"
                      :placeholder="$t('Collection name')"
                      @input="generateSlug(model.name)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('Slug')"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="model.slug"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('Content')"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="short content"
                  >
                    <b-form-textarea
                      id="combo-name"
                      v-model="model.short_content"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <h4 class="mt-4">
                  Danh sách hình ảnh
                </h4>
                <MediaManagement id="MM" v-model="model" />
              </b-col>
            </b-row>
          </BCard>
          <BCard>
            <div class="d-flex flex-wrap align-items-center justify-content-between mb-2" style="gap: 8px;">
              <div class="block">
                <h4>{{ $t('Products in the collection') }}</h4>
                <div>Chọn các sản phẩm phù hợp với bộ sưu tập</div>
              </div>
              <b-button
                v-b-modal.modal-xl
                variant="primary"
                type="button"
                class="ml-auto"
              >
                {{ $t('Add product') }}
              </b-button>
            </div>

            <!-- Advance -->
            <!-- <div class="advance-block mb-2">
              <b-row align-v="center" align-content="between">
                <b-col class="d-flex flex-column justify-content-center">
                  <span class="text-nowrap">{{ $t('Batch setting') }}</span>
                  <div><strong>{{ promotionProductsSelectedCount }}</strong>&nbsp;{{ $t('selected products') }}</div>
                </b-col>
                <b-col align-self="center">
                  <b-button-group class="btn-group">
                    <b-button
                      variant="outline-dark"
                      :class="{'disabled': promotionProductsSelectedCount === 0}"
                      @click="disableAll()"
                    >
                      <span class="text-nowrap">Vô hiệu hóa hàng loạt</span>
                    </b-button>
                    <b-button
                      variant="outline-success"
                      :class="{'disabled': promotionProductsSelectedCount === 0}"
                      @click="enableAll()"
                    >
                      <span class="text-nowrap">Kích hoạt hàng loạt</span>
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      :class="{'disabled': promotionProductsSelectedCount === 0}"
                      @click="deleteAll()"
                    >
                      <span class="text-nowrap">Xóa hàng loạt</span>
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </div> -->

            <!-- table -->
            <vue-good-table
              ref="promotion-products"
              class="custom-good-table"
              :columns="columns"
              :rows="model.list_product"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
              @on-selected-rows-change="selectionChanged"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span class="text-nowrap">
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <div
                  v-if="props.column.field === 'fullName'"
                  class="avatar-name mx-auto"
                >
                  <b-avatar
                    :src="props.row.avatar"
                    size="lg"
                    class="mr-1"
                  />
                  <div class="name">
                    {{ props.row.name }}
                  </div>
                </div>

                <!-- Column: Price -->
                <div
                  v-else-if="props.column.field === 'price'"
                  class="text-center"
                >
                  {{ props.row.price }}
                </div>

                <!-- Column: Status -->
                <div
                  v-else-if="props.column.field === 'status'"
                  class="text-center"
                >
                  <b-form-checkbox
                    v-model="props.row.is_combo_active"
                    name="check-button"
                    switch
                    inline
                    class="mx-auto"
                  />
                </div>

                <!-- Column: Actions -->
                <div
                  v-else-if="props.column.field === 'action'"
                  class="text-center"
                >
                  <b-button
                    variant="flat-danger"
                    class="text-danger btn-icon rounded-circle"
                    @click="handlerDelete(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10','30','50']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </BCard>

          <PopupAddProduct v-model="model" />
          <Fieldset id="SEO" :title="$t('SEO Optimization')">
            <SEOInfo v-model="model" />
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Active')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <hr>
              <div class="d-flex align-items-center justify-content-end mt-2">
                <!-- <b-button variant="outline-danger" type="button">
                Cancel
              </b-button> -->
                <b-button
                  variant="info"
                  type="button"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  {{ $t('Create new') }}
                </b-button>
              </div>
            </Fieldset>
            <!-- <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset> -->
          </div>
        </b-col>
      </b-row>
    </b-form>
    <Overplay v-if="isLoading" />
  </validation-observer>
</template>

<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  // BButtonGroup,
  BForm,
  BFormCheckbox,
  // BInputGroup,
  // BFormRadio,
  BPagination,
  BFormSelect,
  BCard,
  BAvatar,
  BFormTextarea,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import PopupAddProduct from './components/PopupAddProduct.vue'
import MediaManagement from './components/MediaManagement.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BButtonGroup,
    BForm,
    // flatPickr,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    PopupAddProduct,
    // BInputGroup,
    // BFormRadio,
    BPagination,
    BFormSelect,
    BCard,
    BAvatar,
    BFormTextarea,
    MediaManagement,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      isLoading: false,
      model: {
        avatar: this.avatarDefault(),
        video: this.avatarDefault(),
        video_file: null,
        list_thumbnail: [],
        name: null,
        slug: null,
        short_content: null,
        is_active: true,
        list_product: [],
        title_page: null,
        meta_keyword: [],
        meta_description: null,
      },
      rows: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Product',
          field: 'fullName',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Inventory',
          field: 'inventory',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      comboTypeSelected: 'type_percent',
      promotionProductsSelected: [],
      promotionProductsSelectedCount: 0,
    }
  },
  watch: {
    'model.percent'() {
      this.model.percent = this.handlePercent(this.model.percent)
    },
    // 'model.list_product'(val) {
    //   console.log(val)
    // },
  },
  methods: {
    generateSlug(name) {
      this.model.title_page = name
      this.model.slug = this.convertToSlug(name)
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              short_content: this.model.short_content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: this.model.name,
              slug: this.model.slug,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              product_id: [],
              short_content: this.model.short_content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
              avatar: this.model.avatar,
              translation: JSON.stringify(translation),
            }
            const jsonProduct = []
            this.model.list_product.map(item => {
              jsonProduct.push(item.id_number)
            })
            params.product_id = JSON.stringify(jsonProduct)
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.video_file &&
              this.model.video_file !== null
            ) {
              const formData = new FormData()
              formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
              formData.append('type_table', 'album')
              formData.append('type', 'desktop')
              formData.append('avatar', this.model.video_file)
              formData.append('table_field', 'video')
              const resIMG = await Request.post(
                this.$http,
                `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                formData,
              )
              if (resIMG.status === 200) {
                params.video = resIMG.data.data.path
                params.video_id = resIMG.data.data.id
              }
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail.map(async x => {
                const thumbnail = {
                  site_id: JSON.parse(localStorage.getItem('siteID')).id,
                  type_table: 'album',
                  type: 'desktop',
                  avatar: x,
                  table_field: 'list_thumbnail',
                }
                const resIMG = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                  thumbnail,
                )
                if (resIMG.status === 200) {
                  if (listThumbnailId) {
                    listThumbnailId += `,${resIMG.data.data.id}`
                  } else {
                    listThumbnailId = resIMG.data.data.id
                  }
                  listThumbnail.push(resIMG.data.data.path)
                }
                index += 1

                if (index === this.model.list_thumbnail.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handleCreate(params)
                }
              })
            } else {
              this.handleCreate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async handleCreate(params) {
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/album`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Tạo bộ sưu tập thành công!',
            },
          })
          this.$router.push('/album/list')
        } else {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    selectionChanged() {
      this.promotionProductsSelected = this.$refs['promotion-products'].selectedRows
      this.promotionProductsSelectedCount = this.$refs['promotion-products'].selectedRows.length
    },
    disableAll() {
      if (this.promotionProductsSelected.length === 0) {
        this.model.list_product.map(item => {
          // eslint-disable-next-line no-param-reassign
          item.is_combo_active = false
        })
      } else {
        const arrayID = []
        this.promotionProductsSelected.map(item => {
          arrayID.push(item.id)
        })
        arrayID.forEach(id => {
          const item = this.model.list_product.find(val => val.id === id)
          item.is_combo_active = false
        })
      }
    },
    enableAll() {
      if (this.promotionProductsSelected.length === 0) {
        this.model.list_product.map(item => {
          // eslint-disable-next-line no-param-reassign
          item.is_combo_active = true
        })
      } else {
        const arrayID = []
        this.promotionProductsSelected.map(item => {
          arrayID.push(item.id)
        })
        arrayID.forEach(id => {
          const item = this.model.list_product.find(val => val.id === id)
          item.is_combo_active = true
        })
      }
    },
    deleteAll() {
      const arrayID = []
      this.promotionProductsSelected.map(item => {
        arrayID.push(item.id)
      })
      arrayID.map(id => {
        this.model.list_product.splice(this.model.list_product.find(val => val.id === id), 1)
      })
    },
    handlerDelete(id) {
      this.model.list_product.splice(this.model.list_product.findIndex(val => val.id === id), 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.advance-block {
  background: #F6F6F6;
  padding: 1rem;
}
</style>

<style lang="sass">
.form-group--buy
  .input-group-prepend
    .input-group-text
      border: none

    & + .form-control
      border-top-left-radius: 0.357rem !important
      border-bottom-left-radius: 0.357rem !important

.custom-good-table .vgt-table
  font-size: 14px

  thead th
    vertical-align: middle
    text-align: center
    padding-right: 0.75em

  td
    vertical-align: middle

  .avatar-name
    display: flex
    align-items: center
    min-width: 200px

    .name
      max-width: 180px
</style>
